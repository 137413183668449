<!--  -->
<template>
  <div>
    <!-- <TabTopVue></TabTopVue> -->
    <keep-alive>
      <component :is="currentComponent" :houseid="houseid"></component>
    </keep-alive>
    <div class="tablist">
      <HouseList :hourelist="list" @addpage="getpage" @HouseId="getHouseId"></HouseList>
      <SeverePageVue :newdata="sell_house" :buttontitle="'sell_house'" :type="1"></SeverePageVue>
      <SeverePageVue :newdata="contact_us" :buttontitle="'about_us'" :type="2"></SeverePageVue>
      <SeverePageVue :newdata="team_member" :buttontitle="'team_member'" :type="1"></SeverePageVue>

      <!-- <TitlePageVue></TitlePageVue> -->
      <NewList></NewList>

    </div>
  </div>
</template>

<script>
// import TabTopVue from '../components/TabTop.vue'
import HouseList from '@/components/HouseList.vue';
import NewList from '@/components/NewList.vue';
import { houseList, banner, imgText } from '../request/api'
import TitlePageVue from '@/components/TitlePage.vue';
import HomePageVue from '@/components/HomePage.vue';
import SeverePageVue from '../components/SeverePage.vue';

import HouseDetailVue from '@/components/HouseDetail.vue';

export default {
  components: {
    HouseList,
    TitlePageVue,
    HomePageVue,
    HouseDetailVue,
    NewList,
    SeverePageVue
  },
  name: 'TabOne',

  data() {

    return {
      currentComponent: 'HomePageVue',
      page: 1,
      houseid: 0,
      tabtype: 0,
      tabtype1: 0,
      formInline: {
        name: '',
        Search_location: '',
        Type: '',
        Min_bedrooms: '',
        Min_Price: '',
        MinMax_Price: [0, 10000],
        Max_Price: '',
        Exclude_sold_properties: '',
        SEARCH_PROPERTIES: '',
        Distance: '',
        pricetype: '',
        location: { lat: 0, lng: 0 },
        house_type_key: '',
        sell_type: "", //售卖分类ID
        rent_type_id: "", //租房分类id
        radio: 1,
        is_sell: '',
        is_collect:0
      },
      location: { lat: 0, lng: 0 },
      list: [

      ],
      radio: 0,
      severlist: [
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Trust in the value of experience',
          title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
          title3: 'SERVICES WE OFFER',

        },
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Safest pair of hands in Surrey',
          title2: 'We are experts at managing the sales process to ensure a successful completion and our team all live in the local area. When you work with us you are backed by a team of highly skilled market leading agents. We offer a complete range of property services and have chartered planning consultants and surveyors under one roof.',
          title3: 'READ OUR REVIEWS',

        },
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Trust in the value of experience',
          title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
          title3: 'SERVICES WE OFFER',

        },
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Trust in the value of experience',
          title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
          title3: 'SERVICES WE OFFER',

        },
      ],
      our_service: {},
      contact_us: {},
      about_us: {},
      our_team: {},
      join_team: {},
      tenement: {},

      buy_house: {},
      rent_house: {},
      flat: {},
      fitment: {},
      sell_house: {},
      loan: {},
      team_member: {},
    }
  },
  computed: {



    getformInline() {
      return this.$store.state.formInline;
    },

  },
  watch: {

    getformInline: {
      handler: function (newValue, oldValue) {
        // 当nestedData或其内部属性变化时，会调用这个函数
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)
        this.getdata()
        // this.$store.commit('updateformInline', newValue)
      },
      deep: true // 开启深度监听
    }

  },
  methods: {
    // tabclick(item, index) {
    //   this.tabtype = index
    // },
    // tabclick1(item, index) {
    //   this.tabtype1 = index
    // },
    getpage(data) {
      console.log('父组件接收到的数据：' + data)
      if (data) {
        this.page = this.page + 1
        this.getdata()
      }

    },
    getHouseId(data) {
      console.log('父组件接收到的数据：' + data)
      this.houseid = data
      this.$router.push({ name: 'HouseDetail', params: { houseid: this.houseid } })
    },
    initMap() {
      var that = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            that.location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log('location', that.location)
            if (that.location) {
              // that.getdata()
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },

    async getdata() {
      var that = this
      console.log('getformInline', this.getformInline)
      await houseList({
        limit: 10, //每页展示的条数
        page: this.page, //页码
        lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
        lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
        distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
        house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
        house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
        house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
        house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
        house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
        rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
        sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : '', //售价
        is_sell: that.getformInline.is_sell ?Number() : '', //售价
        house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
        is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : ''//排序方式ID
      }).then(res => {
        // this.list = res.data.house_list
        // console.log(this.list)
        var pagelist = []
        pagelist = res.data.house_list
        if (this.page == 1) {
          this.list = pagelist
        }
        else {
          this.list = this.list.concat(pagelist)
        }
        this.$message({
          message: '房源已更新',
          type: 'success'
        });
      })

    },
    async getdata1() {
      var that = this
      console.log('getformInline', this.getformInline)
      await houseList({
        limit: 10, //每页展示的条数
        page: this.page, //页码
        lng: that.location.lng, //经度
        lat: that.location.lat, //纬度
        house_type_key: '', //房屋分类key
        house_pattern_id: '', //房屋房型ID
        house_size_id: '', //房屋户型ID
        house_min_price: '', //最低售价
        house_max_price: '', //最高售价
        rent_type_id: '', //出租
        sell_type: '', //售价
        is_sell: '', //售价
        house_order_type: 1,//排序方式ID
        is_collect:''//排序方式ID

      }).then(res => {
        // this.list = res.data.house_list
        console.log(res)
        var pagelist = []
        pagelist = res.data.house_list
        if (that.page == 1) {
          that.list = pagelist
        }
        else {
          that.list = that.list.concat(pagelist)
        }
        this.$message({
          message: '房源已更新',
          type: 'success'
        });
      })

    },
    async getimgtext() {
      await imgText().then(res => {
        if (res.data) {
          this.our_service = res.data.our_service
          this.contact_us = res.data.contact_us
          this.about_us = res.data.about_us
          this.our_team = res.data.our_team
          this.join_team = res.data.join_team
          this.tenement = res.data.tenement
          this.buy_house = res.data.buy_house
          this.rent_house = res.data.rent_house
          this.flat = res.data.flat
          this.fitment = res.data.fitment
          this.sell_house = res.data.sell_house
          this.loan = res.data.loan
          this.team_member = res.data.team_member



        }

      })
    },

  },
  async created() {
    this.initMap()
    this.getimgtext()

    await banner({
      type: 1
    }).then(res => {

      console.log('res', res)

    })
    this.getdata1()
    // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
  }
}
</script>
<style scoped>
.tabtop {
  position: relative;
  height: 489px;
  margin-bottom: 100px;
}


/* 针对移动端的样式 */
@media (max-width: 800px) {}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
  .tablist {
    /* margin:20px 10vw */
  }

}

.tabbg {
  width: 1920px;
  height: auto;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
}

.searchbox {
  top: 110px;
  left: 471px;
  width: 978px;
  height: 379px;
  background: rgba(0, 0, 0, 0.85);
  margin: 0 auto;
  /* margin-top: -230px; */
  position: absolute;

}

.searchbox1 {
  margin: 44px auto;
  width: 100%;
  text-align: center;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 500;
  font-size: 16px;
  color: #51B1FF;
  line-height: 16px;
  text-align: left;
}

.blue {
  color: #51B1FF;
}

.write {
  color: #fff;

}

.searchinput {
  width: 700px;
}

.searchselect0 {
  width: 100px;
}

.searchselect {
  width: 193px;
}



.searchtabs {
  margin-bottom: 10px;
  justify-content: center;
}

.searchform {
  width: 84%;
  margin: 0 auto;
}

.searchtab {
  padding: 18px;
}

.searchbtns {
  text-align: center;
}

.searchbtn {
  min-width: 100px;
}

.tablist_t {
  margin: 20px 0;

  justify-content: space-between;
}

.w24 {
  width: 24px;
  height: 24px;
}

.botton1 {
  padding: 10px 16px;
  text-align: center;
  margin-right: 20px;
  background: #F5F5F5;
  border-radius: 8px 8px 8px 8px;
  align-items: center;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 500;
  font-size: 22px;
  color: #111111;
  line-height: 48px;

  font-style: normal;
  text-transform: none;
}

.severbox {
  margin-top: 120px;
}
</style>