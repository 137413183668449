import axios from "axios";
import { Toast ,Notify } from "vant";
// import i18n from "@/i18n";
// 封装axios构造函数请求 
// 1.先是自定义一个方法名
// 2.然后获取头部token值
// 3.延长器设不设置都可以
const instance = axios.create({
  baseURL: 'https://nelsons.buzzegg.cn/api/',
  // headers: {
  //   // X-Litemall-Token这个名称就固定这个
  //   // 'X-Litemall-Token': localStorage.getItem("X-Litemall-Token"),
  //   'web-language': localStorage.getItem('lang') == 'en' ? 'en' : 'zh',
  //   // 'Access-Control-Allow-Origin': '*'
  // }
})

// 请求拦截器
// 1.请求拦截器，在发请求之前，请求拦截器可以检测到发起请求之前需要做什么事情，把需要做的事情，放在请求拦截器之前
instance.interceptors.request.use(config => {
  // 1.这里我设置了，在请求之前，先开始进度条的动画
  // 2.返回请求数据
  config.headers['web-language'] = localStorage.getItem('lang') == 'en' ? 'en' : 'zh';
  console.log('sessionStorage.lang', localStorage.getItem('lang'))
  // console.log('i18ni18ni18ni18ni18n', i18n)
  if (localStorage.getItem('token')) {

    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['token'] = token;
  }




  if (localStorage.setItem['X-Litemall-Token'] !== null) {
    // console.headers.common['X-Litemall-Token'] = localStorage.getItem('X-Litemall-Token')
  }
  return config

}, error => {
  console.log(error); //请求失败时的错误处理
  return Promise.reject(error);
}
)
// 响应拦截器
// 1.相应上面的请求拦截器的需求
// 2.把上面的请求拦截器的需求全部做到
instance.interceptors.response.use(res => {
  console.log('res', res)
  if (res.data.code !== 1) {
    // console.log(res.data.msg);
    Notify(res.data.msg);
    
    // this.$message({
    //   message: res.data.msg,
    //   type: 'success'
    // });

    return;
  }

  return res.data
}, error => {
  console.log(error, 'errorerrorerrorerrorerrorerrorerror'); //请求失败时的错误处理
  if (error.response.data.code == 401) {
    console.log(error.response.data.msg);
    Toast.fail(error.response.data.msg);


  }
  return Promise.reject(error);
}
)

// 对外暴露前面创建的构造方法
export default instance
