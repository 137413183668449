<!--  -->
<template>
    <div>
        <div class="Title flex1" v-if="screenWidth >= 801">
            <div class="t_l">
                <img src="../static/index/title.png" alt="" class="w608">
            </div>
            <div class="t_r">
                <div class="title1">
                    {{ $t('Title.title1') }}
                </div>
                <div class="title2">
                    {{ $t('Title.title2') }}
                </div>
                <div class="title3 flex1">
                    <img src="../static/index/title1.png" alt="" class="w48">
                    {{ $t('Title.title3') }}
                </div>
                <div class="title3 flex1">
                    <img src="../static/index/title1.png" alt="" class="w48">
                    {{ $t('Title.title4') }}
                </div>
                <div class="title3 flex1">
                    <img src="../static/index/title1.png" alt="" class="w48">
                    {{ $t('Title.title5') }}
                </div>
                <el-button type="primary"> {{ $t('Title.title6') }}</el-button>
            </div>
        </div>
        <div class="Title" v-else>
            <div class="t_l">
                <img src="../static/index/title.png" alt="" class="w608">
            </div>
            <div class="t_r">
                <div class="title1">
                    {{ $t('Title.title1') }}
                </div>
                <div class="title2">
                    {{ $t('Title.title2') }}
                </div>
                <div class="title3 flex1">
                    <img src="../static/index/title1.png" alt="" class="w48">
                    {{ $t('Title.title3') }}
                </div>
                <div class="title3 flex1">
                    <img src="../static/index/title1.png" alt="" class="w48">
                    {{ $t('Title.title4') }}
                </div>
                <div class="title3 flex1">
                    <img src="../static/index/title1.png" alt="" class="w48">
                    {{ $t('Title.title5') }}
                </div>
                <el-button type="primary"> {{ $t('Title.title6') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            screenWidth: document.body.clientWidth,//初始化宽度
        }
    },
    methods: {
        mounted() {
            const that = this
            window.onresize = () => {
                return (() => {
                    window.screenWidth = document.body.clientWidth
                    that.screenWidth = window.screenWidth
                })()
            }
        },
        watch: {
            screenWidth(val) {
                // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
                this.screenWidth = val
                this.timer = true
            }
        }
        ,
    }
}

</script>

<style scoped>
/* 针对移动端的样式 */
@media (max-width: 800px) {
    .Title {
        margin: 20px;

        /* margin: 20px 280px; */
        margin-bottom: 100px;
    }

    .t_l {
        /* margin: 44px 0 44px 100px; */
    }

    .t_r {
        text-align: left;
        /* margin: 44px 0 44px 100px; */
    }

    .w608 {
        width: 100%;
        /* height: 650px; */
    }

    .title1 {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 23px;
        color: #111111;
        /* line-height: 72px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20px;
    }

    .title2 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 9px;
        color: #666666;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20px;
    }

    .w48 {
        margin-right: 10px;
    }

    .title3 {
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
        color: #9E9E9E;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .Title {
        justify-content: space-between;

        margin: 10px 10vw;
        margin-bottom: 100px;
    }

    .w608 {
        width: 40vw;
        height: 40vw;
    }

    .t_r {
        margin: 44px 0 44px 100px;
    }

    .title1 {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 56px;
        color: #111111;
        /* line-height: 72px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20px;
    }

    .title2 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #666666;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20px;
    }

    .w48 {
        margin-right: 10px;
    }

    .title3 {
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
        color: #9E9E9E;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

}
</style>
