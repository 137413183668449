// router.js
import Vue from 'vue';
import Router from 'vue-router';
// import TabOne from '@/Tabs/TabOne.vue';
// import TabTwo from '@/Tabs/TabTwo.vue';
import TabOneVue from '@/Tabs/TabOne.vue'


import TabTwoVue from '@/Tabs/TabTwo.vue'

import TabThreeVue from '@/Tabs/TabThree.vue'

import TabFourVue from '@/Tabs/TabFour.vue'
import TabFiveVue from '@/Tabs/TabFive.vue'

import TabSixVue from '@/Tabs/TabSix.vue'
import TabSevenVue from '@/Tabs/TabSeven.vue'
import TabEightVue from '@/Tabs/TabEight.vue'
import TabNineVue from '@/Tabs/TabNine.vue'
import TabTenVue from '@/Tabs/TabTen.vue'
import TabElevenVue from '@/Tabs/TabEleven.vue'

import HouseDetail from '@/components/HouseDetail.vue';
import SalesPage from '@/components/SalesPage.vue';
import GoogleMapVue from '@/components/GoogleMap.vue';
// import HomePage from '@/components/HomePage.vue';
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'TabOne',
      component: TabOneVue,
      
    },
    {
      path: 'HouseDetail/:houseid',
      name: 'HouseDetail',
      component: HouseDetail
     

    },
    {
      path: '/TabTwoVue',
      name: 'TabTwo',
      component: TabTwoVue,
      children: [
        {
          path: '/',
          name: 'SalesPageVue',
          component: SalesPage
        },

        // {
        //   path: 'HouseDetail/:houseid',
        //   name: 'HouseDetail',
        //   component: HouseDetail
         
    
        // },

      ]

    },
    {
      path: '/TabThreeVue',
      name: 'TabThree',
      component: TabThreeVue,
      children: [
        {
          path: '/',
          name: 'SalesPageVues',
          component: SalesPage
        },

        // {
        //   path: 'HouseDetail/:houseid',
        //   name: 'HouseDetails',
        //   component: HouseDetail,
          
        // },

      ]
    },
    // {
    //   path: '/HouseDetail/:houseid',
    //   name: 'HouseDetail',
    //   component: HouseDetail
    // },

    {
      path: '/TabFourVue',
      name: 'TabFour',
      component: TabFourVue
    },
    {
      path: '/TabFiveVue',
      name: 'TabFive',
      component: TabFiveVue
    },
    {
      path: '/TabSixVue',
      name: 'TabSix',
      component: TabSixVue
    },
    {
      path: '/TabSevenVue',
      name: 'TabSeven',
      component: TabSevenVue
    },
    {
      path: '/TabEightVue',
      name: 'TabEight',
      component: TabEightVue
    },
    {
      path: '/TabNineVue',
      name: 'TabNine',
      component: TabNineVue
    },
    {
      path: '/TabTenVue',
      name: 'TabTen',
      component: TabTenVue
    },
    {
      path: '/TabElevenVue',
      name: 'TabEleven',
      component: TabElevenVue
    },
    {
      path: '/GoogleMapVue',
      name: 'GoogleMap',
      component: GoogleMapVue
    },
    // ...其他路由
  ]
  
});
// // 全局前置守卫
// Router.beforeEach((to, from, next) => {
//   // 如果目标路由不是从Vue Router内部跳转过来的，则手动添加历史记录
//   if (from.name === null) {
//     Router.go(-1);
//   }
//   next();
// });