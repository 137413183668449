<!--  -->
<template>
    <div>

        <div v-if="hourelist.length == 0">
            <el-empty description="Empty"></el-empty>
        </div>
        <div class="listboxs " v-if="screenWidth >= 801">

            <div class="listbox">
                <div class="listbox11" v-for="(item, index) in hourelist" :key="index" @click="houseDetail(item)">

                    <div class="top">
                        <img :src="item.house_image_text" alt="" class="topimg">
                    </div>
                    <div class="mid flex1">
                        <div class="price" v-if="$i18n.locale == 'en'">
                            £{{ item.en_house_price }}

                        </div>
                        <div class="price" v-else>
                            ￥{{ item.house_price }}

                        </div>
                        <!-- <div class="price" v-else>
                            {{ item.house_price }}
                        </div> -->
                        <div class="cllent">
                            <img src="../static/index/like1.png" v-if="item.is_collect == 1" alt="" class="like">
                            <img src="../static/index/like.png" v-else alt="" class="like">
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="name">{{ item.address }}</div>
                        <div class="name"> {{ $i18n.locale == 'en' ? item.en_house_name : item.house_name }}
                        </div>
                    </div>


                </div>

            </div>
            <div class="more" @click="pageadd">
                {{ $t('More') }}
            </div>

        </div>
        <div class="listboxs " v-else>
            <!-- <div class="texttitle ml40 mb30">
                {{ $t('LATEST_PROPERTIES') }}
            </div> -->
            <div class="listbox" v-for="(item, index) in hourelist" :key="index" @click="houseDetail(item)">
                <div class="top">
                    <img :src="item.house_image_text" alt="" class="topimg">
                </div>
                <div class="mid flex1">
                    <div class="price" v-if="$i18n.locale == 'en'">
                        £{{ item.en_house_price }}

                    </div>
                    <div class="price" v-else>
                        ￥{{ item.house_price }}

                    </div>
                    <!-- <div class="price" v-else>
                            {{ item.house_price }}
                        </div> -->
                    <div class="cllent">
                        <img src="../static/index/like1.png" v-if="item.is_collect == 1" alt="" class="like">
                        <img src="../static/index/like.png" v-else alt="" class="like">
                    </div>
                </div>
                <div class="bottom">
                    <div class="name">{{ item.address }}</div>
                    <div class="name"> {{ $i18n.locale == 'en' ? item.en_house_name : item.house_name }}
                    </div>
                </div>
            </div>
            <div class="more" @click="pageadd">
                {{ $t('More') }}
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'HomeList',
    props: {
        msg: String,
        hourelist: Array
    },



    data() {
        return {
            pricetype: '',
            houseid: 0,
            screenWidth: document.body.clientWidth,//初始化宽度
        }
    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
        // console.log(this.$route.params.houseid,'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    },
    methods: {
        houseDetail(item) {
            console.log(item)
            this.houseid = item.id
            this.$emit('HouseId', this.houseid)
            // 获取到前面打过标签的元素，将其scrollTop属性设置为0
            document.documentElement.scrollTop = 0
            // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
            document.body.scrollTop = 0


        },
        pageadd() {
            this.$emit('addpage', 1)
        },
        onClick(name, title) {

            this.tabtype = title
            this.$emit('HouseId', this.houseid)
        },

    }
}

</script>

<style scoped>
.more {
    margin: 10px auto;
    width: 262px;
    height: 60px;
    text-align: center;
    border: 1px solid #1495FF;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 24px;
    color: #1495FF;
    line-height: 60px;
    cursor: pointer;
    /* text-align: left; */
    font-style: normal;
    text-transform: none;
    margin-bottom: 100px;
}

.w24 {
    width: 24px;
    height: 24px;
}

/* 针对移动端的样式 */
@media (max-width: 800px) {
    body {
        /* background-color: lightblue; */
    }

    .bottons {
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .botton1 {
        cursor: pointer;
        padding: 5px;
        text-align: center;
        margin-right: 5px;
        background: #F5F5F5;
        border-radius: 8px 8px 8px 8px;
        align-items: center;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 22px;
        color: #111111;
        line-height: 48px;

        font-style: normal;
        text-transform: none;
    }

    .tablist {}

    .listboxs {
        text-align: center;
    }

    .listbox {
        margin: 20px;
        cursor: pointer;
    }

    .hide-on-mobile {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .mid {
        /* width: 436px; */
        margin: 16px 0;
        align-items: center;
        justify-content: space-between;
    }


    .topimg {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 8px 8px 8px 8px;
    }

    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        text-align: left;
        margin-bottom: 20px;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        width: 217px;
        height: 24px;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: center;
        font-style: normal;
        text-transform: none;
    }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .listboxs {
        margin: 10px 15vw;
    }

    .shaixuan {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .bottons {
        width: 40%;
        margin-bottom: 20px;
        /* justify-content: space-between; */
    }

    .bottons1 {
        width: 30%;
        margin-bottom: 20px;
        /* justify-content: space-between; */
    }

    .botton1 {
        cursor: pointer;
        padding: 10px 16px;
        text-align: center;
        margin-right: 20px;
        background: #F5F5F5;
        border-radius: 8px 8px 8px 8px;
        align-items: center;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 22px;
        color: #111111;
        line-height: 48px;
        font-style: normal;
        text-transform: none;
    }

    .listbox {
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 30px;
    }

    .listbox11 {
        margin: 20px;
        cursor: pointer;
    }

    .mid {
        width: 100%;
        margin: 16px 0;
        align-items: center;
        justify-content: space-between;
    }

    .top,
    .topimg {
        width: 100%;
        height: 236px;
        border-radius: 3px 3px 3px 3px;
    }

    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        text-align: left;
        margin-bottom: 20px;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        width: 217px;
        height: 24px;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: center;
        font-style: normal;
        text-transform: none;
    }

}
</style>
