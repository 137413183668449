import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
const en = {
    article: {
        text: 'Good morning, the weather is really nice today. Wishing you a wonderful day. Study hard and make progress every day.'
    },
    placeholder: 'Please select',
    // Tab:{
    //     Tab1:'Home',
    //     Tab2:'Sales',
    //     Tab3:'Lettings',
    //     Tab4:'About s',
    //     Tab5:'Meet the team',
    //     Tab6:'NEWS',
    //     Tab7:'Contact us',
    // },
    Tab: ['Home',
        'Sales',
        'Lettings',
        'Service Apartment',
        'Developer',
        'Valuation',
        'Mortgage',
        // 'About us',
        // 'Meet the team',
        // 'News',
        // 'Language',
    ],
    Tab1: [
        'About us',
        'Meet the team',
        'News',
        'Contact us',
    ],
    title1: [
        'Sales',
        'Lettings',

    ],
    bottom: {
        tab1: 'Uesful Link',
        tab2: 'Other Information',
        tabs1: [
            'Home',
            "About",
            "Our team",
            "News",
            "Contact us"
        ],
        name: 'name',
        tel: 'tel',
        email: 'email',
        code: 'Registered in England'
    },
    Form: {
        title1: [
            'Sales',
            'Lettings',

        ],
        title: [
            'ALL PROPERTIES', 'RESOLD HOMES', 'NEW HOMES', 'COMMERCIAL', 'OVERSEAS'
        ],
        Search_location: 'Search location',
        Type: 'Type',
        Min_bedrooms: 'Min bedrooms',
        Min_Price: 'Min Price',
        Max_Price: 'Max Price',
        Exclude_sold_properties: 'Exclude sold_properties',
        SEARCH_PROPERTIES: 'SEARCH PROPERTIES',
        Distance: 'Distance',

    },
    PROPERTIES_FOR_SALE: 'PROPERTIES FOR SALE',
    Save_Search: 'Save Search',
    Map_Search: 'Map Search',
    Price_high_low: 'Price high low',
    Price_low_high: 'Price low high',
    HelloWorld: {
        open: {
            button: 'Click to open Dialog'
        },
        title: 'This is the English title',
        cancel: 'Cancel',
        confirm: 'Confirm',
        close: {
            title: "Are you sure it's closed?",
            cancel: 'Cancel',
            confirm: 'Confirm',
        },
        tips: 'tips'
    },
    Title: {
        title1: 'Let us find you a house to make a home',
        title2: 'We have an extensive database of property listings in various London neighborhoods.We offer detailed information about each property, including floor plans, photos, and key features.',
        title3: 'Extensive property database',
        title4: 'Detailed property information',
        title5: 'Connections to Experts',
        title6: 'BOOK A VALUATION',

    },
    Any_price: 'Any price',
    per_month:'per month',
    LATEST_PROPERTIES: 'LATEST PROPERTIES',
    Share: 'Share',
    Save: 'Save',
    ABOUT_THIS_PROPERTY: 'ABOUT_THIS_PROPERTY',
    COUNCIL_TAX: 'COUNCIL TAX',
    Map: 'Map',
    TRAIN_STATIONS: 'TRAIN STATIONS',
    Mortgage: {
        Mortgage_calculator: 'Mortgage calculator',
        Price: 'Price',
        Deposit: 'Deposit (10%)',
        Loan_term: 'Loan term',
        Interest_rate: 'Interest rate',
        GET_MORTGAGE_ADVICE: 'GET MORTGAGE ADVICE',
        per_month: 'per month'
    },
    Yield: {
        Yield_calculator: 'Yield calculator',
        Property_value: 'Property value',
        Annual_costs: 'Annual costs',
        Monthly_rent: 'Monthly rent',
        Gross: 'Gross',
        Net: 'Net',
        GET_MORTGAGE_ADVICE: 'GET MORTGAGE ADVICE'
    },
    Stamp: {
        Stamp_duty_calculator: 'Stamp duty calculator',
        Property_value: 'Property value',
        q1: 'Are you a first time buyer?',
        q2: 'Are you UK residence??',
        YOU_WILL_PAY: 'YOU WILL PAY:',
        GET_MORTGAGE_ADVICE: 'GET MORTGAGE ADVICE'
    },
    enquiry: {
        Make_enquiry: 'Make an enquiry with our Commercial Property branch',
        First_name: 'First name',
        Last_name: 'Last name',
        Phone: 'Phone',
        Email: 'Email',
        Message: 'Message',
        agree1: 'Get emails with the latest news and information on the local property market, our products and services. You can unsubscribe at any time.',
        agree2: 'I have read and agree to ',
        Terms_Conditions: "Terms and Conditions",
        Privacy_Policy: 'Privacy Policy',
        Cookies_Policy: 'Cookies Policy',
        Make_An_Enquiry: 'Make An Enquiry',
        Call: 'Call'
    },
    about_us: {
        Our_Departments: 'Our Departments:',
        Our_Department: [
            'ESTATE AGENTS & PROPERTY CONSULTANTS',
            'LETTINGS & MANAGEMENT',
            'BLOCK & ESTATE MANAGEMENT',
            'RURAL PROPERTY SERVICES',
            'COMMERCIAL SERVICES',
            'PROFESSIONAL VALUATIONS',
            'PLANNING CONSULTANTS',
            'LAND & NEW HOMES',
        ]
    },
    Contact_us: {
        OUR_BRANCHES: 'OUR BRANCHES',
        Enter: 'Enter your postcode in the search box to find your nearest branch.',
        COMMERCIAL_PROPERTY: 'COMMERCIAL PROPERTY',
        SALES: 'SALES',
        LETTINGS: 'LETTINGS',
        DEPARTMENT: 'PA/ADMINISTRATOR COMMERCIAL DEPARTMENT： Tamsyn Richards',
        VIEW_BRANCH: 'VIEW BRANCH',
        EMAIL_COMMERCIAL_PROPERTY: 'EMAIL COMMERCIAL PROPERTY',
        Company_Address: 'Company Address'
    },
    News: {
        Title: 'News',
        LATEST_NEWS: 'LATEST NEWS',
        Everything: 'Everything from market updates to how we are keeping you safe.',
        READ_ARTICLE: 'READ ARTICLE'
    },
    More: 'MORE PROPERTIES',
    SIMILAR_PROPERTIES:'SIMILAR PROPERTIES',

    login_in: 'Login In',
    register_in:'Register In',
    email:'email',
    GET_MORTGAGE_ADVICE:'GET_MORTGAGE_ADVICE',
    password:'password',
    ...enLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
export default en;
