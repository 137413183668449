// 首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from '@/request/request' 

//地区列表
export function gethomeNavigation() {
  return request({
   method:'POST',
   url:'/product/homeNavigation'
  })
}

//添加房源列表
export function Gethourselist(data) {
  return request({
   method:'POST',
   url:"/house/get",
   data: data
  })
}
export function houseList(data) {
  return request({
   method:'POST',
   url:"/house.house/houseList",
   data: data
  })
}
export function register(data) {
  return request({
   method:'POST',
   url:"/user/register",
   data: data
  })
}

export function login(data) {
  return request({
   method:'POST',
   url:"/user/login",
   data: data
  })
}

export function condition(data) {
  return request({
   method:'POST',
   url:"/house.house/condition",
   data: data
  })
}

export function houseDetail(data) {
  return request({
   method:'POST',
   url:"/house.house/houseDetail",
   data: data
  })
}
export function houseCollect(data) {
  return request({
   method:'POST',
   url:"/house.house/houseCollect",
   data: data
  })
}

export function houseConsult(data) {
  return request({
   method:'POST',
   url:"/house.house/houseConsult",
   data: data
  })
}

export function houseSimi(data) {
  return request({
   method:'POST',
   url:"/house.house/houseSimi",
   data: data
  })
}

export function houseCompute(data) {
  return request({
   method:'POST',
   url:"/compute.compute/houseCompute",
   data: data
  })
}

export function consult(data) {
  return request({
   method:'POST',
   url:"/consult.consult/consult",
   data: data
  })
}


export function imgText(data) {
  return request({
   method:'POST',
   url:"/web.web/imgText",
   data: data
  })
}
export function banner(data) {
  return request({
   method:'POST',
   url:"/web.web/banner",
   data: data
  })
}
export function teamMem(data) {
  return request({
   method:'POST',
   url:"/web.web/teamMem",
   data: data
  })
}
export function newsType(data) {
  return request({
   method:'POST',
   url:"/news.news/newsType",
   data: data
  })
}
export function newsList(data) {
  return request({
   method:'POST',
   url:"/news.news/newsList",
   data: data
  })
}
export function webConfig(data) {
  return request({
   method:'POST',
   url:"/web.web/webConfig",
   data: data
  })
}





