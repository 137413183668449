<!--  -->
<template>
    <div>

        <div class="severe">
            <div class="severboxs" v-if="screenWidth >= 800">
                <div class="severbox flex1">
                    <div class="severbox1 flex1">
                        <div class="sb_r">
                            <div class="sb_r_t">
                                {{ severlist.title }}
                            </div>
                            <div class="sb_r_m">
                                {{ severlist.intro }}
                            </div>
                            <!-- <div class="sb_r_b">
                                {{ severlist.title3 }}
                            </div> -->
                        </div>
                        <div class="sb_l p20">
                            <div class="detail_r_t flex-between flex1">
                                <div class="d_title">
                                    {{ $t('enquiry.Make_enquiry') }}
                                </div>

                            </div>
                            <div class="detail_r_m  mtb15 ">
                                <div class="flex1 mtb15 flex-between">
                                    <div class="d_input_input mtb15">
                                        <el-input v-model="enquiry.First_name" :placeholder="$t('enquiry.First_name') "
                                            class="input_input3"></el-input>
                                    </div>
                                    <div class="d_input_input mtb15">
                                        <el-input v-model="enquiry.Last_name" :placeholder="$t('enquiry.Last_name') "
                                            class="input_input3"></el-input>
                                    </div>
                                </div>

                                <div class=" mtb15 flex-between">
                                    <div class="d_input_input">
                                        <el-input v-model="enquiry.Phone" :placeholder="$t('enquiry.Phone') "
                                            class="input_input2"></el-input>
                                    </div>

                                </div>
                                <div class=" mtb15 flex-between">
                                    <div class="d_input_input">
                                        <el-input v-model="enquiry.Email" :placeholder="$t('enquiry.Email') "
                                            class="input_input2"></el-input>
                                    </div>

                                </div>
                                <div class="flex1 mtb15 flex-between">
                                    <div class="d_input_input">
                                        <el-input v-model="enquiry.Message" :placeholder="$t('enquiry.Message') "
                                            class="input_input2"></el-input>
                                    </div>

                                </div>

                            </div>
                            
                            <div class="detail_r_m1  mtb15 flex1 flex-center" @click="putenquiry1()">
                                <el-button type="primary" class="prl30"> {{ $t('enquiry.Make_An_Enquiry') }}</el-button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="severboxs" v-else>
                <div class="severbox">
                    <div class="">
                        <div class="sb_r">
                            <div class="sb_r_t">
                                {{ severlist.title }}
                            </div>
                            <div class="sb_r_m">
                                {{ severlist.intro }}
                            </div>
                            <!-- <div class="sb_r_b">
                                {{ severlist.title3 }}
                            </div> -->
                        </div>
                        <div class="sb_l">
                            <div class="detail_r_t flex-between flex1">
                                <div class="d_title">
                                    {{ $t('enquiry.Make_enquiry') }}
                                </div>

                            </div>
                            <div class="detail_r_m  mtb15 ">
                                <div class="flex1 mtb15 flex-between">
                                    <div class="d_input_input mtb15 mr10">
                                        <el-input v-model="enquiry.First_name" :placeholder="$t('enquiry.First_name') "
                                            class="input_input3"></el-input>
                                    </div>
                                    <div class="d_input_input mtb15">
                                        <el-input v-model="enquiry.Last_name" :placeholder="$t('enquiry.Last_name') "
                                            class="input_input3"></el-input>
                                    </div>
                                </div>

                                <div class=" mtb15 flex-between">
                                    <div class="d_input_input">
                                        <el-input v-model="enquiry.Phone" :placeholder="$t('enquiry.Phone') "
                                            class="input_input2"></el-input>
                                    </div>

                                </div>
                                <div class=" mtb15 flex-between">
                                    <div class="d_input_input">
                                        <el-input v-model="enquiry.Email" :placeholder="$t('enquiry.Email') "
                                            class="input_input2"></el-input>
                                    </div>

                                </div>
                                <div class="flex1 mtb15 flex-between">
                                    <div class="d_input_input">
                                        <el-input v-model="enquiry.Message" :placeholder="$t('enquiry.Messagesb_l') "
                                            class="input_input2"></el-input>
                                    </div>

                                </div>

                            </div>
                            <!-- <div class="box_bottom_t mb20 input_input2">
                                <el-checkbox-group v-model="enquiry.agree1">
                                    <el-checkbox :label="$t('enquiry.agree1')" name="type"></el-checkbox>

                                </el-checkbox-group>
                                <el-checkbox-group v-model="enquiry.agree2">
                                    <el-checkbox :label="$t('enquiry.agree2')" name="type"></el-checkbox>


                                </el-checkbox-group>

                            </div> -->
                            <div class="detail_r_m1  mtb15 flex1 flex-center" @click="putenquiry1()">
                                <el-button type="primary" class="prl30"> {{ $t('enquiry.Make_An_Enquiry') }}</el-button>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {consult,imgText } from '../request/api'
export default {


    data() {
        return {
            formInline: {
                name: '',
                Search_location: '',
                Type: '',
                Min_bedrooms: '',
                Min_Price: '',
                Max_Price: '',
                Exclude_sold_properties: '',
                SEARCH_PROPERTIES: '',
                Distance: ''
            },
            screenWidth: document.body.clientWidth,//初始化宽度
            houseid: 0,
            tabtype: 0,
            tabtype1: 0,
            list: [],
            radio: 0,
            severlist: {
               

            },
            enquiry: {

                First_name: '',
                Last_name: '',
                Phone: '',
                Email: '',
                Message:'',
                agree1: true,
                agree2: true,

            },

        }
    },

    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            if (!this.timer) {
                // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                this.screenWidth = val
                this.timer = true
                let that = this
                setTimeout(function () {
                    // 打印screenWidth变化的值
                    console.log(that.screenWidth)
                    that.timer = false
                }, 400)
            }
        }
    },
    methods: {
        tabclick(item, index) {
            this.tabtype = index
        },
        tabclick1(item, index) {
            this.tabtype1 = index
        },
        getHouseId(data) {
            console.log('父组件接收到的数据：' + data)
            this.houseid = data
            // if (this.houseid) {
            //   this.currentComponent = 'HouseDetailVue'
            // }
        },
        async putenquiry1() {
            console.log(this.enquiry)
            await consult({
                type: 1,
                name: this.enquiry.First_name, //名
                surname: this.enquiry.Last_name, //姓
                mobile: this.enquiry.Phone, //手机号
                email: this.enquiry.Email, //邮箱
                content: this.enquiry.Message //咨询内容
            }).then(res => {
                if (res.data.code == 1) {
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                }

                console.log('res', res)
            })
        },
        async getdata() {
            await imgText().then(res => {

                this.severlist = res.data.tenement

            })
        }

    },
    async created() {
        this.getdata()
        // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    }
}

</script>
<style scoped>
/* 针对移动端的样式 */
@media (max-width: 800px) {
    body {

        /* background-color: lightblue; */
    }

    .w1000 {
        width: 90%;
        height: auto;
        border-radius: 20px;
    }

    .sb_r {
        width: 80%;
        margin: 10px auto;
        text-align: center;
    }


    .hide-on-mobile {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .sb_l {
        text-align: left;
        padding: 10px;
    }

    .sb_r_b {
        /* padding: 30px; */
        background: #1495FF;
        max-width: 250px;

        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }

    .sb_r_t {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 34px;
        color: #111111;
        /* line-height: 72px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .sb_r_m {
        margin: 20px 0;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        /* line-height: 32px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .sb_l {
        margin: 20px;

        background: #EBECEE;
        border: 1px solid #E5E5E5;

    }

    .input_input1 {
        width: 70%;

    }

    .input_input2 {
        width: 90%;

    }

    .input_input3 {
        width: 90%;



    }

}

.flex1 {
    display: flex;
    /* flex-wrap: wrap; */
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .sb_l {
        /* margin: 20px; */
        background: #EBECEE;
        border: 1px solid #E5E5E5;

    }

    .input_input1 {
        width: 430px;

    }

    .input_input2 {
        width: 348px;

    }

    .input_input3 {
        width: 150px;


    }

    .w1000 {
        width: 80%;
        height: auto;
        border-radius: 20px;
    }

    .tabtop {
        position: relative;
        height: 489px;
        margin-bottom: 100px;
    }

    .tablist {
        margin: 20px 280px;
    }

    .tabbg {
        width: 1920px;
        height: auto;
        border-radius: 0px 0px 0px 0px;
        position: absolute;
    }

    .search {
        margin: 20px 280px;
    }

    .searchbox {
        top: 110px;
        left: 471px;
        width: 978px;
        height: 379px;
        background: rgba(0, 0, 0, 0.85);
        margin: 0 auto;
        /* margin-top: -230px; */
        position: absolute;

    }

    .searchbox1 {
        margin: 44px auto;
        width: 100%;
        text-align: center;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 16px;
        color: #51B1FF;
        /* line-height: 16px; */
        text-align: left;
    }

    .blue {
        color: #51B1FF;
    }

    .write {
        color: #fff;

    }

    .searchinput {
        width: 700px;
    }

    .searchselect0 {
        width: 100px;
    }

    .searchselect {
        width: 193px;
    }



    .searchtabs {
        margin-bottom: 10px;
        justify-content: center;
    }

    .searchform {
        width: 84%;
        margin: 0 auto;
    }

    .searchtab {
        padding: 18px;
    }

    .searchbtns {
        text-align: center;
    }

    .searchbtn {
        min-width: 100px;
    }

    .tablist_t {
        margin: 20px 0;

        justify-content: space-between;
    }

    .w24 {
        width: 24px;
        height: 24px;
    }

    .botton1 {
        padding: 10px 16px;

        /* height: 23px; */
        background: #1495FF;
        text-align: center;
        margin-right: 20px;
        background: #F5F5F5;
        border-radius: 8px 8px 8px 8px;
        align-items: center;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 22px;
        color: #111111;
        /* line-height: 48px; */

        font-style: normal;
        text-transform: none;
    }

    .severbox {
        margin: 10px 10vw;
        margin-top: 120px;
    }
    .severbox1 {
      width: 100%;
      justify-content: start;
    }


    

    .sb_r {
        width: 50%;
        padding: 80px;
        margin: 80px 100px;
        /* margin-right: 200px; */
    }


    .sb_r_t {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 56px;
        color: #111111;
        line-height: 72px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .sb_r_m {
        margin: 20px 0;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        /* line-height: 32px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .sb_r_b {
        /* padding: 30px; */
        background: #1495FF;
        max-width: 250px;

        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }


}
</style>