<template>
  <div class="map">
    <div class="back" @click="pageback()"><img src="../static/back.png" class="back_img" alt=""></div>
    <div class="GmapMapBox">
      <GmapMap @click="clickMap" :center="{ lat: Number(location.lat), lng: Number(location.lng) }" :zoom="12"
        style="width: 100%; height: 80vh">
        <GmapMarker :key="index" v-for="(m, index) in list" :position="{ lat: Number(m.lat), lng: Number(m.lng) }"
          @click="clickMarker(m.position)" />
      </GmapMap>



    </div>
    <SeverePageVue :newdata="team_member" :buttontitle="'team_member'" :type="1"></SeverePageVue>
  </div>
</template>

<script>
import SeverePageVue from '@/components/SeverePage.vue';
import { houseList, imgText } from '../request/api'
export default {
  name: "GoogleMap",
  components: {

    SeverePageVue,

  },
  data() {
    return {
      location: {}, // 中心位置
      center_: { lat: 10.0, lng: 10.0 }, // 保存当前点位置
      currentPlace: null,
      list: [],
      places: [],
      placeName: "",
      dialogVisible: true,
      googlemap: "",
      hasSetPin: false,
      icon: '',
      zoom: 1,
      page: 1, 
      our_service: {},
      contact_us: {},
      about_us: {},
      our_team: {},
      join_team: {},
      tenement: {},

      buy_house: {},
      rent_house: {},
      flat: {},
      fitment: {},
      sell_house: {},
      loan: {},
      team_member: {},
    };
  },
  props: ['gpsName'],
  // 在目标路由的组件中
  created() {

    console.log('this.$route.query.name', this.$route.query.name)
    this.getdata()
    this.getdata1()
    this.initMap()

  },
  mounted() {

  },
  computed: {



    getformInline() {
      return this.$store.state.formInline;
    },

  },
  watch: {

    getformInline: {
      handler: function (newValue, oldValue) {
        // 当nestedData或其内部属性变化时，会调用这个函数
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)

        // this.$store.commit('updateformInline', newValue)
      },
      deep: true // 开启深度监听
    }

  },
  methods: {
    async getdata1() {
      await imgText().then(res => {

        if (res.data) {
          this.our_service = res.data.our_service
          this.contact_us = res.data.contact_us
          this.about_us = res.data.about_us
          this.our_team = res.data.our_team
          this.join_team = res.data.join_team
          this.tenement = res.data.tenement
          this.buy_house = res.data.buy_house
          this.rent_house = res.data.rent_house
          this.flat = res.data.flat
          this.fitment = res.data.fitment
          this.sell_house = res.data.sell_house
          this.loan = res.data.loan
          this.team_member = res.data.team_member



        }

      })
      // await banner({
      //     type: 0
      // }).then(res => {

      //     console.log('res', res)

      // })
    },
    pageback() {
      this.$router.go(-1);
    },
    async getdata() {
      var that = this
      console.log('getformInline', this.getformInline)
      await houseList({
        limit: 10, //每页展示的条数
        page: this.page, //页码
        lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
        lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
        distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
        house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
        house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
        house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
        house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
        house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
        rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
        sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : '', //售价
        is_sell: that.getformInline.is_sell ? Number(that.getformInline.is_sell) : '', //售价
        house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1//排序方式ID
      }).then(res => {
        // this.list = res.data.house_list
        // console.log(this.list)
        var pagelist = []
        pagelist = res.data.house_list
        if (this.page == 1) {
          this.list = pagelist
        }
        else {
          this.list = this.list.concat(pagelist)
        }
        this.$message({
          message: '房源已更新',
          type: 'success'
        });
      })

    },
    initMap() {
      var that = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            that.location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log('location', that.location)
            if (that.location) {
              // that.getdata()
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    handleClose() { },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarkerFun();
    },
    addMarker() {
      this.addMarkerFun();
    },
    addMarkerFun() {
      if (this.currentPlace) {
        this.hasSetPin = true
        this.zoom = 10
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        // this.list = [];
        // this.list.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.center_ = marker;
        this.placeName = this.currentPlace.name;
        this.currentPlace = null;
      }
    },


    clickMap(e) {
      this.hasSetPin = true
      let longlat = e.latLng.lat() + "," + e.latLng.lng();
      console.log('longlat', longlat)
      this.center_ = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      // this.list = [];
      // this.list.push({ position: this.center_ });
    },
    clickMarker(val) {
      this.center = val;
    },
    submitMap() {
      if (!this.hasSetPin) {
        this.msgError(this.$t("googlemap.searchAndAdd"));
        return
      }
      let obj = Object.assign({}, this.center_);
      obj.name = `${this.center_.lat.toFixed(5)},${this.center_.lng.toFixed(5)}`;
      this.$emit("setMap", obj);
    },
    cancelMap() {
      this.$emit("closeMap");
    },
  },
};
</script>
<style scoped>
.map {
  /* position: relative; */
  /* height: 85vh; */
  /* padding-top: 10vh; */
}

.back_img {
  position: absolute;
  top: 10px;
  z-index: 100;
  left: 10px;
  width: 32px;
  height: 32px;
}

.add-btn {
  width: 60px;
  margin-left: 2%;
  height: 40px;
  background: #409eff;
  color: #fff;
  border: 0;
  border-radius: 5px;
}

.search-box input {
  height: 40px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 7px;
  outline: none;
}

/* 底部两个按钮 */
.btn-box {
  margin-top: 2vh;
}

.btn-box .el-button {
  padding: 8px 30px;
  border-radius: 30px;
  width: 140px;
  height: 40px;
}

.confirm {
  color: #fff;
  background-color: #0778bc;
}

.cancel {
  color: #0778bc;
  border: 1px solid #0778bc;
}

.cursor {
  cursor: pointer;
}

@media only screen and (max-width: 820px) {
  .img-box .el-image {
    height: 4vh;
  }

  .search-box input {
    height: 5vh;
  }

  .add-btn {
    height: 5vh;
  }
}

.map .GmapMapBox {
  /* background-image: url(../assets/img/loading.gif); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
</style>